@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #cacaca;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #007bff;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #6565FF;
}

/* Number input styles */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}